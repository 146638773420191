import * as React from "react"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import { JsonDebugger } from "../../components/json-debugger"
import { Button, Checkbox, Form, Input, Tag, Typography } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { Link } from "gatsby"
import { loginForm } from "./account.module.less"
import { StoreContext } from "../../context/store-context"
import PageContent from "../../components/page-content"

const { Title, Link: AntLink } = Typography

const ProfilePage: React.FC = (data) => {
  const { loading, customer } = React.useContext(StoreContext)

  return (
    <Layout>
      <Seo title="Profile" />
      <PageContent>
        <Title>Profile</Title>
        {loading && <p>Loading...</p>}
        {!customer ||
          (!customer.authenticated && <Link to={"/account/login"}>Login</Link>)}
        {customer && customer.authenticated && (
          <>
            <Title level={2}>{customer.displayName}</Title>
            <Title level={3}>{customer.email}</Title>
            {customer.tags &&
              customer.tags.map((tag, i) => <Tag key={tag || i}>{tag}</Tag>)}
          </>
        )}
        {/* <JsonDebugger json={customer} /> */}
      </PageContent>
    </Layout>
  )
}

export default ProfilePage
